

class CepFinder {
	constructor() {
		this.forms = document.querySelectorAll('.cep-finder');
        this.findForms();
        this.googleApiKey = 'AIzaSyCoYlYpNMG9HHHa0bar_pOPjbNak7ScOic';
    }
    
    findForms() {
        console.log('CepFinder')
		this.forms.forEach(form => {
            form.querySelectorAll('.cep-input').forEach(input => {
                input.addEventListener('keyup', (e) => {
                    console.log('cep', e.target.value)
                    if (e.target.value.length == 9) {
                        this.pesquisacep(e.target.value);
                    } else {
                        this.limpa_formulário_cep();
                    }
                })
            })
            form.querySelectorAll('.street_number').forEach(input => {
                input.addEventListener('keyup', (e) => {
                    console.log('street_number', e.target.value)
                    this.pesquisaLatLng(document.getElementById('address').value + ' ' + e.target.value);
                })
            })
		})
    }


    limpa_formulário_cep() {
        //Limpa valores do formulário de cep.
        document.getElementById('address').value=("");
        document.getElementById('neighborhood').value=("");
        document.getElementById('city').value=("");
        document.getElementById('state').value=("");
    }

    insereValores(conteudo) {
        console.log('insereValores', conteudo)
        if (!("erro" in conteudo)) {
            //Atualiza os campos com os valores.
            document.getElementById('address').value=(conteudo.logradouro);
            document.getElementById('neighborhood').value=(conteudo.bairro);
            document.getElementById('city').value=(conteudo.localidade);
            document.getElementById('state').value=(conteudo.uf);
        } //end if.
        else {
            //CEP não Encontrado.
            this.limpa_formulário_cep();
            alert("CEP não encontrado.");
        }
    }

    insereLatLng(conteudo) {
        console.log('insereLatLng', conteudo)
        if (conteudo.status === 'OK') {
        // if (conteudo.results.length > 0) {
            const result = conteudo.results[0];
            const lat = result.geometry.location.lat;
            const lng = result.geometry.location.lng;
            document.getElementById('lat').value=(lat);
            document.getElementById('lng').value=(lng);
        }
    }

    pesquisaLatLng(valor) {
        const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${valor}&key=${this.googleApiKey}`;
        console.log('finding URL', url)

        if(self.fetch) {
            fetch(url).then(response => {
                response.json().then(json => this.insereLatLng(json))
            });

        } else {
            // faça alguma coisa com XMLHttpRequest?
            alert('2 - Browser não permite requisições Fetch =(');
        }

    }
        
    pesquisacep(valor) {
        console.log('')
        console.log('BUSCA CEP', valor)
        console.log('')

        //Nova variável "cep" somente com dígitos.
        var cep = valor.replace(/\D/g, '');

        //Verifica se campo cep possui valor informado.
        if (cep != "") {

            //Expressão regular para validar o CEP.
            var validacep = /^[0-9]{8}$/;

            //Valida o formato do CEP.
            if(validacep.test(cep)) {

                const url = `https://viacep.com.br/ws/${cep}/json/`;

                if(self.fetch) {
                    // execute minha solicitação do fetch aqui
                    console.log('has fetch');

                    fetch(url).then(response => {
                        response.json().then(json => this.insereValores(json))
                        this.pesquisaLatLng(document.getElementById('address').value + ' ' + cep);
                    });

                } else {
                    // faça alguma coisa com XMLHttpRequest?
                    alert('1 - Browser não permite requisições Fetch =(');
                }

            } //end if.
            else {
                //cep é inválido.
                this.limpa_formulário_cep();
                alert("Formato de CEP inválido.");
            }
        } //end if.
        else {
            //cep sem valor, limpa formulário.
            this.limpa_formulário_cep();
        }
    }
}

export default CepFinder;